// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { PresetFilter, OfferType, Language, TDA, Application, Environment, APIs } = initSchema(schema);

export {
  PresetFilter,
  OfferType,
  Language,
  TDA,
  Application,
  Environment,
  APIs
};