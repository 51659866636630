/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Radio, RadioGroupField } from "@aws-amplify/ui-react";
export default function EnvironmentSelection(props) {
  const { environment, ...rest } = props;
  return (
    <RadioGroupField
      padding="0px 0px 0px 0px"
      position="relative"
      alignItems="flex-start"
      gap="5px"
      direction="row"
      {...rest}
    >
      <Radio
        size="small"
        shrink="0"
        gap="8px"
        display="flex"
        name="environment"
        value={environment?.value}
        height="20px"
        children={environment?.name}
      ></Radio>
    </RadioGroupField>
  );
}
