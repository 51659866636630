/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { CheckboxField, Flex } from "@aws-amplify/ui-react";
export default function FilterSelection(props) {
  const { presetFilter, overrides: overridesProp, ...rest } = props;
  const overrides = { ...overridesProp };
  return (
    <Flex
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      position="relative"
      alignItems="flex-start"
      gap="10px"
      direction="row"
      {...rest}
      {...getOverrideProps(overrides, "Flex")}
    >
      <CheckboxField
        size="small"
        shrink="0"
        labelPosition="start"
        gap="8px"
        display="flex"
        name={presetFilter?.value}
        label={presetFilter?.name}
        height="24px"
        {...getOverrideProps(overrides, "Flex.CheckboxField[0]")}
      ></CheckboxField>
    </Flex>
  );
}
