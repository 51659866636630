import React, { useCallback, useContext, useMemo, useState } from "react";
import { GlobalContext } from "../GlobalState";
import ReportGenerator from "../util/report-generator";

import { Button } from "@aws-amplify/ui-react";

function GenerateReport(props) {
  const { fullReport } = props;
  const store = useContext(GlobalContext);
  const [disabled, setDisabled] = useState(false);
  const [progress, setProgress] = useState("");
  const { application, languages, tdas } = store;
  const isDisabled = useMemo(() => {
    if (!application) return true;
    if (languages.length === 0) return true;
    if (tdas.length === 0) return true;
    return false;
  }, [application, languages, tdas]);

  const generate = useCallback(() => {
    const report = new ReportGenerator(store, fullReport);

    report.generate(setProgress, setDisabled);
  }, [setProgress, store, fullReport]);
  return useMemo(
    () => (
      <div>
        <Button
          loadingText=""
          isDisabled={disabled || isDisabled}
          onClick={() => generate()}
          ariaLabel=""
        >
          Pull Report
        </Button>
        <pre>{progress}</pre>
      </div>
    ),
    [progress, isDisabled, generate, disabled]
  );
}

export default GenerateReport;
