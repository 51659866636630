import React, { createContext, useReducer } from "react";
import AppReducer from "./AppReducer";
import { DataStore } from "@aws-amplify/datastore";
import { TDA } from "./models";
const initialState = {
  application: "bat",
  languages: ["en"],
  environment: "production",
  tdas: [],
  offerTypes: [],
  presetFilters: [],
  effectiveDate: "",
};

export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  function setApplication(payload) {
    dispatch({
      type: "SET_APPLICATION",
      payload,
    });
  }

  function setEnvironment(payload) {
    dispatch({
      type: "SET_ENVIRONMENT",
      payload,
    });
  }

  function addLanguage(payload) {
    dispatch({
      type: "ADD_LANGUAGE",
      payload,
    });
  }

  function removeLanguage(payload) {
    dispatch({
      type: "REMOVE_LANGUAGE",
      payload,
    });
  }

  function addTDA(payload) {
    dispatch({
      type: "ADD_TDA",
      payload,
    });
  }

  function removeTDA(payload) {
    dispatch({
      type: "REMOVE_TDA",
      payload,
    });
  }

  function addOfferType(payload) {
    dispatch({
      type: "ADD_OFFER_TYPE",
      payload,
    });
  }

  function removeOfferType(payload) {
    dispatch({
      type: "REMOVE_OFFER_TYPE",
      payload,
    });
  }

  function addPresetFilters(payload) {
    dispatch({
      type: "ADD_PRESET_FILTERS",
      payload,
    });
  }

  function removePresetFilters(payload) {
    dispatch({
      type: "REMOVE_PRESET_FILETERS",
      payload,
    });
  }

  function setEffectiveDate(payload) {
    dispatch({
      type: "SET_EFFECTIVE_DATE",
      payload,
    });
  }

  async function selectAllTDAs(payload) {
    const tdas = await DataStore.query(TDA);
    dispatch({
      type: "SELECT_ALL_TDAS",
      payload,
      tdas,
    });
  }

  function deselectAllTDAs(payload) {
    dispatch({
      type: "DESELECT_ALL_TDAS",
      payload,
    });
  }
  /*function generateReport() {
    dispatch({
      type: "GENERATE_REPORT",
    });
  }*/

  return (
    <GlobalContext.Provider
      value={{
        application: state.application,
        environment: state.environment,
        languages: state.languages,
        tdas: state.tdas,
        offerTypes: state.offerTypes,
        presetFilters: state.presetFilters,
        effectiveDate: state.effectiveDate,
        setApplication,
        setEnvironment,
        addLanguage,
        removeLanguage,
        addTDA,
        removeTDA,
        addOfferType,
        removeOfferType,
        addPresetFilters,
        removePresetFilters,
        setEffectiveDate,
        selectAllTDAs,
        deselectAllTDAs,
        /*generateReport,*/
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
