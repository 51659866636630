/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React, { useMemo, useContext } from "react";
import { nanoid } from "nanoid";
import { TDA } from "../models";
import {
  getOverrideProps,
  useDataStoreBinding,
} from "@aws-amplify/ui-react/internal";
import TDASelection from "../components/TDASelection";
import { GlobalContext } from "../GlobalState";
import { Collection } from "@aws-amplify/ui-react";

const includesAll = (tdas, regionTdas) => {
  const tests = [];
  //console.log("regionTdas: ", regionTdas);
  //console.log("tdas: ", tdas);
  regionTdas.forEach((t) => {
    tests.push(tdas.includes(t.name));
  });
  /*console.log("tests: ", tests);*/
  return tests.every((v) => v);
};
const checkAllRegions = (regionTdas, tdas) => {
  //console.log("regionTdas: ", regionTdas);
  //console.log("tdas: ", tdas);
  regionTdas.forEach((t, i) => {
    const reg = regionTdas[i];
    reg.checked = includesAll(tdas, reg.tdas);
  });

  return regionTdas;
};

export default function RegionSelectionCollection(props) {
  const {
    report,
    tda,
    items: itemsProp,
    overrides: overridesProp,
    ...rest
  } = props;
  const overrides = { ...overridesProp };
  const { tdas, addTDA, removeTDA } = useContext(GlobalContext);
  const items =
    itemsProp !== undefined
      ? itemsProp
      : useDataStoreBinding({
          type: "collection",
          model: TDA,
        }).items;

  const regions = useMemo(() => {
    if (!items.length) return;
    const reduced = items.reduce((region, item) => {
      const name = item.region;
      const reg = region.find((r) => r.name === name);
      if (reg) {
        reg.tdas.push(item);
      } else {
        region.push({ name, tdas: [item] });
      }
      return region;
    }, []);

    checkAllRegions(reduced, tdas);

    return reduced;
  }, [items, tdas]);

  return (
    <Collection
      templateColumns="1fr 1fr"
      type="grid"
      alignItems="stretch"
      justifyContent="stretch"
      autoFlow="row"
      items={regions || []}
      {...rest}
      {...getOverrideProps(overrides, "Collection")}
      onChange={(e) => {
        e.stopPropagation();
        const name = e.target.value;
        const checked = e.target.checked;
        const region = regions.find((r) => r.name === name);
        if (checked) {
          region.tdas.forEach((tda) => {
            addTDA(tda.name);
          });
        } else {
          region.tdas.forEach((tda) => {
            removeTDA(tda.name);
          });
        }
      }}
    >
      {(item, index) => {
        /*const checked = includesAll(tdas, item.tdas);*/
        /*console.log("checked: ", checked);*/
        //console.log("item: ", item);
        return (
          <TDASelection
            tda={{ name: item.name }}
            width="auto"
            margin="0 0 0 0"
            checked={item.checked}
            key={nanoid()}
            {...getOverrideProps(overrides, "Collection.TDASelection[0]")}
          ></TDASelection>
        );
      }}
    </Collection>
  );
}
