/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React, { useContext } from "react";
import { Language } from "../models";
import {
  getOverrideProps,
  useDataStoreBinding,
} from "@aws-amplify/ui-react/internal";
import { nanoid } from "nanoid";
import LanguageSelection from "./LanguageSelection";
import { GlobalContext } from "../GlobalState";
import { Collection } from "@aws-amplify/ui-react";
export default function LanguageSelectionCollection(props) {
  const {
    language,
    items: itemsProp,
    overrides: overridesProp,
    ...rest
  } = props;
  const overrides = { ...overridesProp };
  const { languages, addLanguage, removeLanguage } = useContext(GlobalContext);

  const items =
    itemsProp !== undefined
      ? itemsProp
      : useDataStoreBinding({
          type: "collection",
          model: Language,
        }).items;
  return (
    <Collection
      type="list"
      alignItems="stretch"
      direction="row"
      items={items || []}
      {...rest}
      {...getOverrideProps(overrides, "Collection")}
      onChange={(e) => {
        e.stopPropagation();
        const { value, checked } = e.target;
        if (checked) {
          addLanguage(value);
        } else {
          removeLanguage(value);
        }
      }}
    >
      {(item, index) => {
        //console.log("item: ", item);
        const checked = languages.includes(item.value);

        return (
          <LanguageSelection
            language={item}
            key={nanoid()}
            checked={checked}
            {...getOverrideProps(overrides, "Collection.LanguageSelection[0]")}
          ></LanguageSelection>
        );
      }}
    </Collection>
  );
}
