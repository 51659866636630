/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React, { useContext } from "react";
import { OfferType } from "../models";
import {
  getOverrideProps,
  useDataStoreBinding,
} from "@aws-amplify/ui-react/internal";
import OfferTypeSelection from "../ui-components/OfferTypeSelection";
import { Collection } from "@aws-amplify/ui-react";

import { GlobalContext } from "../GlobalState";

export default function OfferTypeSelectionCollection(props) {
  const {
    offerType,
    items: itemsProp,
    overrides: overridesProp,
    ...rest
  } = props;
  const overrides = { ...overridesProp };
  const { offerTypes, addOfferType, removeOfferType } = useContext(
    GlobalContext
  );

  const items =
    itemsProp !== undefined
      ? itemsProp
      : useDataStoreBinding({
          type: "collection",
          model: OfferType,
        }).items;
  return (
    <Collection
      templateColumns="1fr 1fr 1fr"
      type="grid"
      alignItems="stretch"
      justifyContent="stretch"
      autoFlow="row"
      items={items || []}
      {...rest}
      {...getOverrideProps(overrides, "Collection")}
      onChange={(e) => {
        e.stopPropagation();
        const { value, checked } = e.target;
        /*console.log("value: ", value, checked);*/
        if (checked) {
          addOfferType(value);
        } else {
          removeOfferType(value);
        }
      }}
    >
      {(item, index) => (
        <OfferTypeSelection
          width="190px"
          offerType={item}
          margin="0 0 0 0"
          key={item.id}
          {...getOverrideProps(overrides, "Collection.OfferTypeSelection[0]")}
        ></OfferTypeSelection>
      )}
    </Collection>
  );
}
