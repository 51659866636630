/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React, { useContext, useMemo } from "react";
import { Application } from "../models";
import { nanoid } from "nanoid";
import {
  getOverrideProps,
  useDataStoreBinding,
} from "@aws-amplify/ui-react/internal";
import ApplicationSelection from "./ApplicationSelection";
import { Collection } from "@aws-amplify/ui-react";
import { GlobalContext } from "../GlobalState";
export default function ApplicationSelectionCollection(props) {
  const {
    applicationState,
    items: itemsProp,
    resetSelectAll,
    overrides: overridesProp,
    ...rest
  } = props;
  const overrides = { ...overridesProp };
  //const [applications, setApplications] = applicationState;
  const { application, setApplication } = useContext(GlobalContext);

  const items =
    itemsProp !== undefined
      ? itemsProp
      : useDataStoreBinding({
          type: "collection",
          model: Application,
        }).items;
  return useMemo(
    () => (
      <Collection
        type="list"
        alignItems="stretch"
        direction="row"
        items={items?.sort((a, b) => a.sort - b.sort) || []}
        {...rest}
        {...getOverrideProps(overrides, "Collection")}
        onChange={(e) => {
          e.stopPropagation();
          resetSelectAll(false);
          setApplication(e.target?.value);
        }}
      >
        {(item, index) => (
          <ApplicationSelection
            application={item}
            key={nanoid()}
            defaultValue={application}
            {...getOverrideProps(
              overrides,
              "Collection.ApplicationSelection[0]"
            )}
          ></ApplicationSelection>
        )}
      </Collection>
    ),
    [application, setApplication, items, overrides, rest]
  );
}
