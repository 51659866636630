import { useContext, useState } from "react";
import {
  Authenticator,
  Heading,
  Flex,
  Button,
  Divider,
  ToggleButton,
  CheckboxField,
  View,
  Image,
  useTheme,
} from "@aws-amplify/ui-react";
/*import { useTheme } from "@aws-amplify/ui";*/
import ApplicationSelectionCollection from "./components/ApplicationSelectionCollection";
import EnvironmentSelectionCollection from "./components/EnvironmentSelectionCollection";
import LanguageSelectionCollection from "./components/LanguageSelectionCollection";
import TDASelectionCollection from "./components/TDASelectionCollection";
import RegionSelectionCollection from "./components/RegionSelectionCollection";
import OfferTypeSelectionCollection from "./components/OfferTypeSelectionCollection";
import FilterSelectionCollection from "./components/FilterSelectionCollection";
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
/*import ReportGenerator from "./util/report-generator";*/
import GenerateReport from "./components/GenerateReport";
import { GlobalContext } from "./GlobalState";

//const report = new ReportGenerator();
const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image alt="Amplify logo" src="/logo.jpg" />
      </View>
    );
  },
};

function App() {
  const [isPressed, setIsPressed] = useState(false);
  const [checked, setChecked] = useState(false);
  const {
    application,
    setEffectiveDate,
    selectAllTDAs,
    deselectAllTDAs,
  } = useContext(GlobalContext);
  return (
    <Authenticator components={components}>
      {({ signOut, user }) => {
        const [username, at] = user.attributes.email.split("@");
        return (
          <div className="App">
            <Flex
              direction="column"
              width="400px"
              alignItems="flex-start"
              padding="20px"
              gap="30px"
            >
              <Flex alignItems="center">
                <div>Logged In:</div>

                <Flex direction="column" className="user" gap="0rem">
                  <div>{username}</div>
                  <span>@{at}</span>
                </Flex>
                <Button
                  size="small"
                  onClick={async () => {
                    signOut();
                  }}
                >
                  Sign out
                </Button>
              </Flex>
              <Heading level={3}>OATms Parser</Heading>

              <Flex direction="column">
                <Heading level={6}>Environments</Heading>
                <EnvironmentSelectionCollection />
              </Flex>
              <Flex direction="column">
                <Heading level={6}>Languages</Heading>
                <LanguageSelectionCollection />
              </Flex>
              <Flex direction="column">
                <Heading level={6}>Applications</Heading>
                <ApplicationSelectionCollection resetSelectAll={setIsPressed} />
              </Flex>
              <Flex direction="column">
                <Flex>
                  <Heading level={6}>
                    {application === "tcuv" ? "Regions" : "TDAs"}
                  </Heading>
                  <ToggleButton
                    className="selectall-btn"
                    size="small"
                    isPressed={isPressed}
                    onClick={(e) => {
                      if (isPressed) {
                        deselectAllTDAs();
                      } else {
                        selectAllTDAs();
                      }
                      setIsPressed(!isPressed);
                    }}
                  >
                    {isPressed ? "DeSelect All" : "Select All"}
                  </ToggleButton>
                </Flex>
                {application === "tcuv" ? (
                  <RegionSelectionCollection />
                ) : (
                  <TDASelectionCollection />
                )}
              </Flex>
              <Flex direction="column">
                <Heading level={6}>Offer Type Filter</Heading>
                <OfferTypeSelectionCollection />
              </Flex>
              <Flex direction="column">
                <Heading level={6}>Preset Filter</Heading>
                <FilterSelectionCollection />
              </Flex>
              <Flex direction="column">
                <Heading level={6}>Effective Date</Heading>
                <input
                  type="date"
                  onChange={(e) => {
                    setEffectiveDate(e.target.value);
                  }}
                />
              </Flex>
              <Divider padding="10px 0" />
              <Flex alignItems="baseline" width="300px" direction="column">
                <CheckboxField
                  checked={checked}
                  onChange={(e) => setChecked(e.target.checked)}
                  label="Full Report"
                  name="full"
                  value="no"
                  size="small"
                />
                <GenerateReport fullReport={checked} />
              </Flex>
            </Flex>
          </div>
        );
      }}
    </Authenticator>
  );
}

export default App;
