/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { Radio, RadioGroupField } from "@aws-amplify/ui-react";
export default function EnvironmentSelection(props) {
  const { application, ...rest } = props;
  return (
    <RadioGroupField
      padding="0px 0px 0px 0px"
      position="relative"
      alignItems="flex-start"
      gap="5px"
      direction="row"
      {...rest}
    >
      <Radio
        size="small"
        shrink="0"
        gap="8px"
        display="flex"
        name="application"
        value={application?.value}
        height="20px"
        children={application?.name}
      ></Radio>
    </RadioGroupField>
  );
}
