/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React, { useContext, useMemo } from "react";
import { nanoid } from "nanoid";
import { TDA } from "../models";
import {
  getOverrideProps,
  useDataStoreBinding,
} from "@aws-amplify/ui-react/internal";
import TDASelection from "./TDASelection";
import { GlobalContext } from "../GlobalState";
import { Collection } from "@aws-amplify/ui-react";
export default function TDASelectionCollection(props) {
  const { tda, items: itemsProp, overrides: overridesProp, ...rest } = props;
  const overrides = { ...overridesProp };
  const { tdas, addTDA, removeTDA } = useContext(GlobalContext);
  const items =
    itemsProp !== undefined
      ? itemsProp
      : useDataStoreBinding({
          type: "collection",
          model: TDA,
        }).items;
  return useMemo(
    () => (
      <Collection
        templateColumns="1fr 1fr 1fr 1fr 1fr"
        type="grid"
        alignItems="stretch"
        justifyContent="stretch"
        autoFlow="row"
        items={items?.sort((a, b) => a.sort - b.sort) || []}
        {...rest}
        {...getOverrideProps(overrides, "Collection")}
        onChange={(e) => {
          e.stopPropagation();
          const { value, checked } = e.target;
          if (checked) {
            addTDA(value);
          } else {
            removeTDA(value);
          }
        }}
      >
        {(item, index) => {
          const checked = tdas.includes(item.name);
          return (
            <TDASelection
              tda={item}
              checked={checked}
              width="auto"
              margin="0 0 0 0"
              key={nanoid()}
              {...getOverrideProps(overrides, "Collection.TDASelection[0]")}
            ></TDASelection>
          );
        }}
      </Collection>
    ),
    [tdas, items, rest, overrides, addTDA, removeTDA]
  );
}
