export default function reducer(state, action) {
  switch (action.type) {
    case "SET_APPLICATION":
      return {
        ...state,
        application: action.payload,
        /*tdas: action.payload === "tcuv" ? [] : state.tdas,*/
        tdas: [],
      };

    case "SET_ENVIRONMENT":
      return {
        ...state,
        environment: action.payload,
      };

    case "ADD_LANGUAGE":
      return {
        ...state,
        languages: [...state.languages, action.payload],
      };

    case "REMOVE_LANGUAGE":
      return {
        ...state,
        languages: [...state.languages.filter((l) => l !== action.payload)],
      };

    case "ADD_TDA":
      console.log("add tda ", action.payload);
      return {
        ...state,
        tdas: [...state.tdas, action.payload],
      };

    case "REMOVE_TDA":
      return {
        ...state,
        tdas: [...state.tdas.filter((t) => t !== action.payload)],
      };

    case "ADD_OFFER_TYPE":
      return {
        ...state,
        offerTypes: [...state.offerTypes, action.payload],
      };

    case "REMOVE_OFFER_TYPE":
      return {
        ...state,
        offerTypes: [...state.offerTypes.filter((o) => o !== action.payload)],
      };

    case "ADD_PRESET_FILTERS":
      return {
        ...state,
        presetFilters: [...state.presetFilters, action.payload],
      };

    case "REMOVE_PRESET_FILETERS":
      return {
        ...state,
        presetFilters: [
          ...state.presetFilters.filter((f) => f !== action.payload),
        ],
      };

    case "SET_EFFECTIVE_DATE":
      return {
        ...state,
        effectiveDate: action.payload,
      };

    case "SELECT_ALL_TDAS":
      const tdas = action.tdas.map((t) => t.name);
      return {
        ...state,
        tdas: tdas,
      };

    case "DESELECT_ALL_TDAS":
      return {
        ...state,
        tdas: [],
      };
    /*case "GENERATE_REPORT":
      const report = new ReportGenerator(state);

      report.generate();

      return state;*/

    default:
      return state;
  }
}
