import { DataStore } from "@aws-amplify/datastore";
import { APIs, TDA } from "../models";
import ExcelJS from "exceljs";

export default class ReportGenerator {
  constructor(state, pullFullReport) {
    //console.log("construct: ", state);
    this.application = state.application;
    this.environment = state.environment;
    this.tda = state.tdas;
    this.language = state.languages;
    this.offerType = state.offerTypes;
    this.presetFilters = state.presetFilters;
    this.effectiveDate = state.effectiveDate;
    this.pullFullReport = pullFullReport;
    // array of report objects
    this.apis = [];
    this.tdas = [];
    this.setProgress = {};
  }

  async getAPIs() {
    this.apis = await DataStore.query(APIs);
    this.tdas = await DataStore.query(TDA);
    /*console.log("apis: ", this.apis);
    console.log("tdas: ", this.tdas);*/
  }

  buildEffectiveDate() {
    return this.effectiveDate
      ? `&isPreview=y&effectiveDate=${this.effectiveDate}`
      : ``;
  }

  buildCalls() {
    const calls = [];
    this.language.forEach((lang) => {
      this.tda.forEach((tda) => {
        calls.push({
          app: this.application,
          env: this.environment,
          lang,
          tda,
        });
      });
    });
    return calls;
  }

  async fetchReports() {
    return new Promise(async (resolve, reject) => {
      const calls = this.buildCalls();
      /*console.log("calls: ", calls);*/
      const reports = [];
      //calls.forEach(async (call) => {
      for (let i = 0; i < calls.length; i += 1) {
        const call = calls[i];
        const { zip } = this.tdas.find((t) => t.name === call.tda);
        /*console.log("call.env: ", call);
        console.log("zip: ", zip);
        console.log("apis: ", this.apis);*/
        const { url, secretKey, clientID } = this.apis.find(
          (p) => p.application === call.app && p.environment === call.env
        );
        const req = `${url}${zip}?clientId=${clientID}&brandId=1&lang=${
          call.lang
        }${this.buildEffectiveDate()}`;

        const headers = { "x-api-key": secretKey };
        /*console.log("request: ", req);*/
        let report;
        try {
          report = await fetch(req, {
            medthod: "GET",
            headers,
          });
          this.setProgress(
            `Parsing... [${Math.ceil((i / calls.length) * 100)}%] Complete`
          );
        } catch (e) {
          reject(
            "An error occurred while fetching a report.  Please check your keys"
          );
          break;
        }
        const raw = await report.json();
        const json = raw[0];
        console.log("report data: ", json);
        const code = json?.code;
        const regionCode = json?.regionCode;
        const urlRedirect = json?.urlRedirect;
        const containsOffers = json?.containsOffers;
        const tdaName = json?.tdaName;
        const offers = json?.offerBundle?.offers;
        console.log("offers: ", offers);
        offers.forEach((offer) => {
          const parsed =
            this.application === "tcuv"
              ? this.parseTCUV(tdaName, code, regionCode, offer, call.lang)
              : this.application === "bat"
              ? this.parseBAT(
                  tdaName,
                  code,
                  regionCode,
                  offer,
                  urlRedirect,
                  containsOffers,
                  call.lang
                )
              : this.parseRCT(
                  tdaName,
                  code,
                  regionCode,
                  offer,
                  urlRedirect,
                  containsOffers,
                  call.lang
                );
          reports.push(parsed);
        });
      }
      //console.log("returning reports");
      resolve(reports);
    });
  }

  parseTCUV(tdaName, code, regionCode, offer, lang) {
    /*console.log("offer: ", offer);*/
    const {
      id,
      offerId,
      offerType,
      title,
      startDate,
      endDate,
      seriesList: { series },
      lease,
      description,
      disclaimers: { disclaimer },
      fundingSource,
      ...rest
    } = offer;

    const disclaimers = disclaimer.length
      ? disclaimer.reduce((prev, curr) => `${prev} | ${curr}`)
      : "";

    /*const bulletText = bullets?.bullet?.reduce(
      (prev, curr) => `${prev} | ${curr.text}`,
      ""
    );*/
    const leaseDispositionFee = lease?.dispositionFee || "";

    const tiers = offer?.apr?.tiers?.tier?.map((t) => t.level);

    /*console.log("this.tdas: ", this.tdas, code);*/
    const region = this.tdas.find((t) => t.name === code).region;

    const seriesYear = series
      .map((s) => `${s.id} ${s.year.replace(/,/g, " ")}`)
      .join(",");

    /*
    const short = this.tdas.find((m) => m.name === code).short;

    rest.link = `https://www.buyatoyota.com/${
      lang === "es" ? "es/" : ""
    }${short}/offer-detail/?offerid=${rest.offerId}`;
*/

    delete rest.offerImage;
    delete rest.offerImageAlt;
    delete rest.offerImageDisclaimer;
    return {
      application: `${this.application} (${this.environment})`,
      tdaName,
      region,
      regionCode,
      offerId,
      offerType,
      startDate,
      endDate,
      //seriesId: series[0]?.id || "",
      seriesYear,
      fundingSource,
      hasNulls: this.hasNulls({ title, description, disclaimers }),
      has0DispFee: this.has0DispFee({ leaseDispositionFee }),
      leaseDispositionFee,
      tiers,
      title,
      description,
      disclaimers,
      ...(this.pullFullReport && rest),
    };
  }

  parseBAT(
    tdaName,
    code,
    regionCode,
    offer,
    urlRedirect,
    containsOffers,
    lang
  ) {
    const {
      id,
      offerId,
      offerType,
      title,
      seriesList: { series },
      lease,
      description,
      bullets,
      disclaimers: { disclaimer },
      cash,
      miscellaneous,
      multivehicle,
      ...rest
    } = offer;

    const disclaimers = disclaimer.length
      ? disclaimer.reduce((prev, curr) => `${prev} | ${curr}`)
      : "";

    const bulletText = bullets?.bullet?.reduce(
      (prev, curr) => `${prev} | ${curr.text}`,
      ""
    );

    const bulletTextLinks = bullets?.bullet?.reduce(
      (prev, curr) => `${prev} | ${curr.link}`,
      ""
    );

    const leaseDispositionFee = lease?.dispositionFee || "";
    const seriesYear = Array.isArray(series)
      ? series.map((s) => `${s.year.replace(/,/g, " ")}`).join(",")
      : series.year;

    const seriesName = Array.isArray(series)
      ? series.map((s) => `${s.name.replace(/,/g, " ")}`).join(",")
      : series.name;

    const short = this.tdas.find((m) => m.name === code).short;

    rest.link = `https://www.buyatoyota.com/${
      lang === "es" ? "es/" : ""
    }${short}/offer-detail/?offerid=${rest.offerId}`;
    rest.regionCode = regionCode;
    rest.urlRedirect = urlRedirect;
    rest.containsOffers = containsOffers;
    rest.seriesIncluded = series?.included;
    rest.seriesExcluded = series?.excluded;
    rest.cashAmount = cash?.cashAmount;
    rest.cashMaxAprTerm = cash?.maxAprTerm;
    rest.cashLeaseTerm = cash?.maxLeaseTerm;
    rest.cashRequiresTfsFinancing = cash?.requiresTfsFinancing;
    rest.cashStackableWithApr = cash?.stackableWithApr;
    rest.cashStackableWithLease = cash?.stackableWithLease;
    rest.cashTypeLabels = cash?.subTypeLabels;
    rest.cashCannotBeCombinedWith = cash?.cannotBeCombinedWith;
    rest.leaseAcquisitionFee = lease?.leaseAcquisitionFee;
    rest.leaseDispositionFee = lease?.dispositionFee;
    rest.leaseSubventionDollarAmount = lease?.subventionDollarAmount;
    rest.leaseDealerCash = lease?.dealerCash;
    rest.leaseDownPayment = lease?.downPayment;
    rest.leaseVehicleSellingPrice = lease?.vehicleSellingPrice;
    rest.leaseDASAcquisitionFee = lease?.dueAtSigning?.acquisitionFee;
    rest.leaseDASMonthlyPayment = lease?.dueAtSigning?.monthlyPayment;
    rest.leaseDASSecurityDeposit = lease?.dueAtSigning?.securityDeposit;
    rest.miscSubTypeLabel = miscellaneous?.subTypeLabels;
    rest.multiCashAmount = multivehicle?.cashAmount;
    rest.multiSubTypeLabels = multivehicle?.subTypeLabels;
    rest.multiStackableWithLease = multivehicle?.stackableWithLease;
    rest.multiMaxLeaseTerm = multivehicle?.maxLeaseTerm;
    rest.multiStackableWithApr = multivehicle?.stackableWithApr;
    rest.multiMaxAprTerm = multivehicle?.maxAprTerm;
    rest.multiRequiresTfsFinancing = multivehicle?.requiresTfsFinancing;
    rest.mulitCannotBeCombinedWith = multivehicle?.cannotBeCombinedWith;

    delete rest.offerId;

    return {
      application: `${this.application} (${this.environment})`,
      tdaName,
      code,
      offerId,
      offerType,
      seriesName, //: series?.name || "",
      seriesYear, //: series?.year || "",
      title,
      hasNulls: this.hasNulls({ description, bulletText, disclaimers }),
      has0DispFee: this.has0DispFee({ leaseDispositionFee }),
      hasNoTargetAudience: this.hasNoTargetAudience({ bulletText }),
      leaseDispositionFee,
      description,
      bulletText,
      bulletTextLinks,
      disclaimers,
      ...(this.pullFullReport && rest),
    };
  }

  parseRCT(
    tdaName,
    code,
    regionCode,
    offer,
    urlRedirect,
    containsOffers,
    lang
  ) {
    const {
      id,
      offerId,
      offerType,
      title,
      seriesList: { series },
      lease,
      description,
      bullets,
      disclaimers: { disclaimer },
      offerImage,
      offerCard: { cardImage, ...offerCard },
      cash,
      miscellaneous,
      multivehicle,
      ...rest
    } = offer;

    const disclaimers = disclaimer.length
      ? disclaimer.reduce((prev, curr) => `${prev} | ${curr}`)
      : "";

    const bulletText = bullets?.bullet?.reduce(
      (prev, curr) => `${prev} | ${curr.text}`,
      ""
    );

    const bulletTextLinks = bullets?.bullet?.reduce(
      (prev, curr) => `${prev} | ${curr.link}`,
      ""
    );

    const leaseDispositionFee = lease?.dispositionFee || "";

    const seriesYear = Array.isArray(series)
      ? series.map((s) => `${s.year.replace(/,/g, " ")}`).join(",")
      : series.year;

    const seriesName = Array.isArray(series)
      ? series.map((s) => `${s.name.replace(/,/g, " ")}`).join(",")
      : series.name;

    const short = this.tdas.find((m) => m.name === code).short;

    rest.link = `https://www.toyota.com/${
      lang === "es" ? "espanol/" : ""
    }${short}/deals-incentives/${rest.offerId}/`;
    rest.regionCode = regionCode;
    rest.urlRedirect = urlRedirect;
    rest.containsOffers = containsOffers;
    rest.seriesIncluded = series?.included;
    rest.seriesExcluded = series?.excluded;
    rest.cashAmount = cash?.cashAmount;
    rest.cashMaxAprTerm = cash?.maxAprTerm;
    rest.cashLeaseTerm = cash?.maxLeaseTerm;
    rest.cashRequiresTfsFinancing = cash?.requiresTfsFinancing;
    rest.cashStackableWithApr = cash?.stackableWithApr;
    rest.cashStackableWithLease = cash?.stackableWithLease;
    rest.cashTypeLabels = cash?.subTypeLabels;
    rest.cashCannotBeCombinedWith = cash?.cannotBeCombinedWith;
    rest.leaseAcquisitionFee = lease?.leaseAcquisitionFee;
    rest.leaseDispositionFee = lease?.dispositionFee;
    rest.leaseSubventionDollarAmount = lease?.subventionDollarAmount;
    rest.leaseDealerCash = lease?.dealerCash;
    rest.leaseDownPayment = lease?.downPayment;
    rest.leaseVehicleSellingPrice = lease?.vehicleSellingPrice;
    rest.leaseDASAcquisitionFee = lease?.dueAtSigning?.acquisitionFee;
    rest.leaseDASMonthlyPayment = lease?.dueAtSigning?.monthlyPayment;
    rest.leaseDASSecurityDeposit = lease?.dueAtSigning?.securityDeposit;
    rest.miscSubTypeLabel = miscellaneous?.subTypeLabels;
    rest.multiCashAmount = multivehicle?.cashAmount;
    rest.multiSubTypeLabels = multivehicle?.subTypeLabels;
    rest.multiStackableWithLease = multivehicle?.stackableWithLease;
    rest.multiMaxLeaseTerm = multivehicle?.maxLeaseTerm;
    rest.multiStackableWithApr = multivehicle?.stackableWithApr;
    rest.multiMaxAprTerm = multivehicle?.maxAprTerm;
    rest.multiRequiresTfsFinancing = multivehicle?.requiresTfsFinancing;
    rest.mulitCannotBeCombinedWith = multivehicle?.cannotBeCombinedWith;
    rest.offerCard = offerCard;

    delete rest.offerId;
    
    return {
      application: `${this.application} (${this.environment})`,
      tdaName,
      code,
      offerId,
      offerType,
      seriesName, //: series?.name || "",
      seriesYear, //: series?.year || "",
      title,
      hasNoSeriesName: seriesName === "", 
      hasBATLinks: this.hasBATLink({ bulletTextLinks }),
      hasNulls: this.hasNulls({ description, bulletText, disclaimers }),
      has0DispFee: this.has0DispFee({ leaseDispositionFee }),
      hasNoTargetAudience: this.hasNoTargetAudience({ bulletText }),
      hasMissingImages: this.hasMissingImages({ offerImage, cardImage }),
      offerImage: offerImage,
      cardImage: cardImage,
      leaseDispositionFee,
      description,
      bulletText,
      bulletTextLinks,
      disclaimers,
      ...(this.pullFullReport && rest),
    };
  }

  saveByteArray(reportName, byte) {
    var blob = new Blob([byte], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    link.click();
  }

  hasNulls(r) {
    return this.application === "tcuv"
      ? (r.disclaimers.match(/null/gi) ||
          r.title.match(/null/gi) ||
          r.description.match(/null/gi)) !== null
      : (r.bulletText.match(/null/gi) ||
          r.disclaimers.match(/null/gi) ||
          r.description.match(/null/gi)) !== null;
  }

  hasNoTargetAudience(r) {
    if (this.application === "tcuv") return false;
    return (
      (r.bulletText.match(/for \./gi) || r.bulletText.match(/for ,/gi)) !== null
    );
  }

  hasMissingImages(r) {
    if (this.application !== "rct") return false;
    /*console.log("r.offerImage: ", r.offerImage);*/
    return r.offerImage === "" || r.cardImage === "";
  }

  hasBATLink(r) {
    if (this.application !== "rct") return false;
    return r.bulletTextLinks.match(/buyatoyota\.com/gi) !== null;
  }

  has0DispFee(r) {
    return r.leaseDispositionFee === "0";
  }

  hightlight(row, argb, filter) {
    row.eachCell((c) => {
      //console.log("c: ", c);
      if (filter && c._column._key === filter) {
        c.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb },
        };
      }
    });
  }

  filterByPresetFilters(report) {
    /*console.log("this.presetFilters: ", this.presetFilters);*/
    const tests = this.presetFilters.map((f) => {
      if (f === "nulls" && report.hasNulls) return "passed";
      if (f === "0dispfee" && report.has0DispFee) return "passed";
      if (f === "targetaudience" && report.hasNoTargetAudience) return "passed";

      return "failed";
    });
    /*if (report.offerId === "372444") {
      console.log("report: ", report);
      console.log("tests: ", tests);
    }*/
    return tests.includes("passed");
  }

  async generate(setProgress, setDisabled) {
    setDisabled(true);
    this.setProgress = setProgress;
    /*console.log("this stuff: ", this);*/
    this.setProgress("fetching APIs...");
    await this.getAPIs();

    this.setProgress("fetching Reports...");
    let reports;
    try {
      reports = await this.fetchReports();
    } catch (e) {
      setDisabled(false);
      return this.setProgress(e);
    }
    /*console.log("reports after reject: ", reports);*/
    this.setProgress("Generating Excel file...");
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("My Sheet");
    if (!reports.length) {
      setDisabled(false);
      return this.setProgress("No offers found!");
    }
    const columns = Object.keys(reports[0]).map((key) => {
      return { header: key, key };
    });
    //console.log("columns: ", columns);
    worksheet.columns = columns;
    //console.log("this.offerType: ", this.offerType);
    reports.forEach((report) => {
      // filter based on offerType
      const offerTypes =
        this.offerType?.map((o) => {
          return this.application === "tcuv" ? `TCUV ${o}` : o;
        }) || [];
      if (this.offerType.length && !offerTypes.includes(report.offerType)) {
        return;
      }

      // filter based on preset filters
      //console.log("this.presetFilters: ", this.filters);
      if (this.presetFilters.length && !this.filterByPresetFilters(report)) {
        return;
      }

      const row = worksheet.addRow(report);

      // check for nulls
      if (this.hasNulls(report)) {
        this.hightlight(row, "ffee8f", "hasNulls");
      }

      //check for target audience
      if (this.hasNoTargetAudience(report)) {
        this.hightlight(row, "bfefff", "hasNoTargetAudience");
      }

      // check for 0 fees
      if (this.has0DispFee(report)) {
        this.hightlight(row, "ffbfbf", "has0DispFee");
      }

      if (report.hasNoSeriesName) {
        this.hightlight(row, "dbffde", "hasNoSeriesName");
      }

      if (this.hasBATLink(report)) {
        this.hightlight(row, "d5b0ff", "hasBATLinks");
      }

      if (report.hasMissingImages) {
        this.hightlight(row, "76b5c5", "hasMissingImages");
      }
    });

    this.setProgress("Done!");
    setDisabled(false);
    const buffer = await workbook.xlsx.writeBuffer();
    this.saveByteArray("Report", buffer);
  }
}
