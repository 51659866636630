/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React, { useContext, useMemo } from "react";
import { Environment } from "../models";
import {
  getOverrideProps,
  useDataStoreBinding,
} from "@aws-amplify/ui-react/internal";
import EnvironmentSelection from "./EnvironmentSelection";
import { Collection } from "@aws-amplify/ui-react";
import { GlobalContext } from "../GlobalState";
export default function EnvironmentSelectionCollection(props) {
  const { items: itemsProp, overrides: overridesProp, ...rest } = props;
  const overrides = { ...overridesProp };
  const { environment, setEnvironment } = useContext(GlobalContext);
  const items =
    itemsProp !== undefined
      ? itemsProp
      : useDataStoreBinding({
          type: "collection",
          model: Environment,
        }).items;
  return useMemo(
    () => (
      <Collection
        type="list"
        alignItems="stretch"
        direction="row"
        items={items?.sort((a, b) => a.sort - b.sort) || []}
        {...rest}
        {...getOverrideProps(overrides, "Collection")}
        onChange={(e) => {
          e.stopPropagation();
          setEnvironment(e.target?.value);
        }}
      >
        {(item, index) => {
          return (
            <EnvironmentSelection
              environment={item}
              key={item.id}
              defaultValue={environment}
              {...getOverrideProps(
                overrides,
                "Collection.EnvironmentSelection[0]"
              )}
            ></EnvironmentSelection>
          );
        }}
      </Collection>
    ),
    [items, setEnvironment]
  );
}
