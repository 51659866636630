/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-west-2",
  aws_content_delivery_bucket: "oatparser-20211223154621-hostingbucket-dev",
  aws_content_delivery_bucket_region: "us-west-2",
  aws_content_delivery_url: "https://d1b7ez31yfnyr1.cloudfront.net",
  aws_appsync_graphqlEndpoint:
    "https://wlkaracumzdepi2dzlli6h2uxi.appsync-api.us-west-2.amazonaws.com/graphql",
  aws_appsync_region: "us-west-2",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_cognito_identity_pool_id:
    "us-west-2:a78941d1-7c9f-4984-a4ba-488e7647b713",
  aws_cognito_region: "us-west-2",
  aws_user_pools_id: "us-west-2_ItIniHPYE",
  aws_user_pools_web_client_id: "1hl9lh7ir2cd40205vsbur1ola",
  oauth: {
    domain: "mje3o9c0uls0-prod.auth.us-west-2.amazoncognito.com",
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn: window.location.origin,
    redirectSignOut: window.location.origin,
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: ["GOOGLE"],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [
      "REQUIRES_LOWERCASE",
      "REQUIRES_NUMBERS",
      "REQUIRES_SYMBOLS",
      "REQUIRES_UPPERCASE",
    ],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
};

export default awsmobile;
