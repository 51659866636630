/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React, { useContext } from "react";
import { PresetFilter } from "../models";
import {
  getOverrideProps,
  useDataStoreBinding,
} from "@aws-amplify/ui-react/internal";
import FilterSelection from "../ui-components/FilterSelection";
import { Collection } from "@aws-amplify/ui-react";
import { GlobalContext } from "../GlobalState";

export default function FilterSelectionCollection(props) {
  const {
    presetFilter,
    items: itemsProp,
    overrides: overridesProp,
    ...rest
  } = props;
  const overrides = { ...overridesProp };
  const { presetFilters, addPresetFilters, removePresetFilters } = useContext(
    GlobalContext
  );

  const items =
    itemsProp !== undefined
      ? itemsProp
      : useDataStoreBinding({
          type: "collection",
          model: PresetFilter,
        }).items;
  /*console.log("items filter: ", items);*/
  return (
    <Collection
      templateColumns="1fr 1fr 1fr"
      type="grid"
      alignItems="stretch"
      justifyContent="stretch"
      autoFlow="row"
      items={items || []}
      {...rest}
      {...getOverrideProps(overrides, "Collection")}
      onChange={(e) => {
        e.stopPropagation();
        const { name, checked } = e.target;
        /*console.log("value: ", name, checked);*/
        if (checked) {
          addPresetFilters(name);
        } else {
          removePresetFilters(name);
        }
      }}
    >
      {(item, index) => (
        <FilterSelection
          width="150px"
          presetFilter={item}
          margin="0 0 0 0"
          height="auto"
          key={item.id}
          {...getOverrideProps(overrides, "Collection.FilterSelection[0]")}
        ></FilterSelection>
      )}
    </Collection>
  );
}
